import httpUtil from '../utils/httpUtil'

/**短视频管理列表 */

export const queryPage = params => httpUtil.post("/api/crmPc/shortVideo/queryPage", params);

/**添加视频 */
export const add = params => httpUtil.post("/api/crmPc/shortVideo/add", params);

/**删除视频 */

export const deleteById = params => httpUtil.post("/api/crmPc/shortVideo/deleteById", params);
/**上架视频 */
export const updateData = params => httpUtil.post("/api/crmPc/shortVideo/updateData", params);

/**商品列表 */
export const getGoodsInfoPC = params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPC", params);

/**编辑 */
export const update = params => httpUtil.post("/api/crmPc/shortVideo/update", params);